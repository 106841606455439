import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const drugLabelList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'drugsticker/list',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('Get drug label list err : ', err)

      return {}
    })

  return response
}
const drugLabelAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'drugsticker',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('Add drug label err : ', err)

      return {}
    })

  return response
}
const drugLabelUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'drugsticker',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('Update drug label err : ', err)

      return {}
    })

  return response
}
const drugLabelRemove = async id => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'drugsticker',
      param: id,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('Remove drug label err : ', err)

      return {}
    })

  return response
}

export default {
  drugLabelList,
  drugLabelAdd,
  drugLabelUpdate,
  drugLabelRemove,
}
