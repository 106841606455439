<template>
  <div>
    <v-card>
      <v-card-title class='px-2'>
        {{ $t('drug_lable_list') }}
        <v-spacer></v-spacer>
        <v-btn
          color='primary'
          class='me-2 d-none d-md-block'
          @click='isAddNewDrugLabel = true'
        >
          <v-icon class='me-1'>
            {{ icons.mdiPlus }}
          </v-icon>
          <span>{{ $t('add_drug_label') }}</span>
        </v-btn>
        <v-btn
          color='primary'
          class='me-2 d-block d-md-none'
          fab
          icon
          outlined
          @click='isAddNewDrugLabel = true'
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
        <v-btn
          color='secondary'
          outlined
          class='d-none d-md-block'
          :disabled='!selected.length'
          @click='isPrintDrugLabel = true;aloneIdPrint = null'
        >
          <v-icon class='me-1'>
            {{ icons.mdiPrinter }}
          </v-icon>
          <span>{{ $t('printing_drug_labels') }}</span>
        </v-btn>
        <v-btn
          color='secondary'
          outlined
          false
          icon
          class='d-block d-md-none'
          :disabled='!selected.length'
          @click='isPrintDrugLabel = true;aloneIdPrint = null'
        >
          <v-icon>
            {{ icons.mdiPrinter }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class='px-2'>
        <v-col
          cols='12'
          md='6'
          class='py-0'
          lg='4'
        >
          <v-text-field
            v-model='searchtext'
            :label="$t('search')"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols='12'
          md='6'
          class='py-0'
          lg='4'
        >
          <v-dialog
            ref='date'
            v-model.trim='isShowDate'
            :return-value.sync='drugsticker_modify'
            width='290px'
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-text-field
                v-model='drugsticker_modify'
                outlined
                readonly
                dense
                :label="$t('date')"
                v-bind='attrs'
                v-on='on'
              >
                <template v-slot:append>
                  <v-tooltip
                    color='#212121'
                    top
                  >
                    <template v-slot:activator='{ on, attrs }'>
                      <v-icon
                        color='primary'
                        v-bind='attrs'
                        v-on='on'
                        @click="drugsticker_modify = ''"
                      >
                        {{ icons.mdiCalendarBlank }}
                      </v-icon>
                    </template>
                    <span>{{ $t('all_dates') }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model.trim='drugsticker_modify'
              :locale='$i18n.locale'
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color='primary'
                @click='$refs.date.save(drugsticker_modify)'
              >
                {{ $t('confirm') }}
              </v-btn>
              <v-btn
                text
                color='primary'
                @click='isShowDate = false'
              >
                {{ $t('cancel') }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-data-table
        v-model='selected'
        :headers='columns'
        :items='dataTableList'
        :options.sync='options'
        :server-items-length='totalDataTableList'
        :footer-props='footer'
        :loading='loading'
        disable-sort
        item-key='drugsticker_id'
        show-select
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.drugsticker_id`]='{ index }'>
          {{ segmentId + index + 1 }}
        </template>
        <template v-slot:[`item.order_id`]='{ item }'>
          <router-link
            v-if='item.order_id'
            :to="{ name: 'order-detail', params: { id: item.order_id_pri } }"
            class='font-weight-medium text-decoration-none'
          >
            {{ item.order_id }}
          </router-link>
          <span v-else>
            -
          </span>

        </template>
        <template v-slot:[`item.drugsticker_price`]='{ item }'>
          {{ item.drugsticker_price | formatPrice }}
        </template>
        <template v-slot:[`item.drugsticker_expdate`]='{ item }'>
          <ConverseDate :date='item.drugsticker_expdate' />
        </template>
        <template v-slot:[`item.actions`]='{ item }'>
          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                icon
                color='primary'
                v-bind='attrs'
                v-on='on'
                @click='isPrintDrugLabel = true; aloneIdPrint =  item.drugsticker_id'
              >
                <v-icon>{{ icons.mdiPrinter }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('print') }}</span>
          </v-tooltip>
          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                icon
                color='primary'
                v-bind='attrs'
                @click='dataEdit = item; isEditDrugLabel = true'
                v-on='on'
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>
          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                icon
                color='primary'
                v-bind='attrs'
                @click='drugsticker_id = item.drugsticker_id;isDelete = true'
                v-on='on'
              >
                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('delete') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data='options.page'
        :page-count='totalPage'
        :segment-id='+segmentId '
        :count-list='dataTableList.length'
        :total='+totalDataTableList'
        @pageChanged='(page)=>{options.page = page ; fetchDataTable()}'
        @itemChanged='(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}'
      />
    </v-card>
    <AddNewDrugLabel
      v-model='isAddNewDrugLabel'
      @onAdd="fetchDataTable('add')"
    />
    <EditDrugLabel
      v-model='isEditDrugLabel'
      :data-edit='dataEdit'
      @onUpdate="fetchDataTable('update')"
    />
    <RemoveDrugLabel
      :id='drugsticker_id'
      v-model='isDelete'
      @onDelete="fetchDataTable('delete')"
    />
    <v-dialog
      v-model='isPrintDrugLabel'
      persistent
      max-width='300'
    >
      <v-card>
        <v-card-title>
          {{ $t('printing_drug_labels') }}
        </v-card-title>
        <v-card-text>
          <label>
            {{ $t('choose_language') }}
          </label>
          <v-radio-group
            v-model='language'
          >
            <v-radio
              label='ไทย'
              value='1'
            ></v-radio>
            <v-radio
              label='English'
              value='2'
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color='primary'
            @click='sendPrint(null)'
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color='secondary'
            @click='isPrintDrugLabel = false'
            outlined
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiPlus, mdiPencilOutline,
  mdiDeleteOutline, mdiPrinter, mdiCheck, mdiCalendarBlank,
} from '@mdi/js'
import useDrugLabel from './useDrugLabel'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import { formatPrice } from '@/plugins/filters'
import AddNewDrugLabel from './AddNewDrugLabel.vue'
import EditDrugLabel from './EditDrugLabel.vue'
import RemoveDrugLabel from './RemoveDrugLabel.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    ConverseDate,
    AddNewDrugLabel,
    EditDrugLabel,
    Pagination,
    RemoveDrugLabel,
  },
  filters: {
    formatPrice,
  },
  setup() {
    return {
      ...useDrugLabel(),
      icons: {
        mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiPrinter, mdiCheck, mdiCalendarBlank,
      },
    }
  },
}
</script>

<style lang='scss' scoped>

</style>
