var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"900"},model:{value:(_vm.isAddNewDrugLabel),callback:function ($$v) {_vm.isAddNewDrugLabel=$$v},expression:"isAddNewDrugLabel"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('add_drug_label'))+" ")]),_c('v-form',{ref:"formAddDrugLabel",on:{"submit":function($event){$event.preventDefault();return _vm.createDrugLabel.apply(null, arguments)}}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"outlined":"","label":_vm.$t('list_drug_equipment'),"dense":"","return-object":"","search-input":_vm.search,"filter":_vm.customFilter,"loading":_vm.drugLoading,"no-data-text":_vm.drugLoading?_vm.$t('data_loading'):_vm.$t('no_information'),"items":_vm.dataDrugList,"item-value":"drug_id_pri"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" ("+_vm._s(item.drug_id)+") "+_vm._s(item.drug_name)+" "),_c('v-spacer'),_c('DrugStatus',{staticClass:"me-2",attrs:{"type":item.drug_category_id}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" ("+_vm._s(item.drug_id)+") "+_vm._s(item.drug_name)+" ")]}}]),model:{value:(_vm.drug),callback:function ($$v) {_vm.drug=$$v},expression:"drug"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-dialog',{ref:"date",attrs:{"return-value":_vm.drugsticker_expdate,"width":"290px"},on:{"update:returnValue":function($event){_vm.drugsticker_expdate=$event},"update:return-value":function($event){_vm.drugsticker_expdate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","hide-details":"","readonly":"","rules":[_vm.required],"dense":"","label":_vm.$t('date')},model:{value:(_vm.drugsticker_expdate),callback:function ($$v) {_vm.drugsticker_expdate=$$v},expression:"drugsticker_expdate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isShowDate),callback:function ($$v) {_vm.isShowDate=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"isShowDate"}},[_c('v-date-picker',{attrs:{"locale":_vm.$i18n.locale,"scrollable":""},model:{value:(_vm.drugsticker_expdate),callback:function ($$v) {_vm.drugsticker_expdate=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"drugsticker_expdate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.date.save(_vm.drugsticker_expdate)}}},[_vm._v(" "+_vm._s(_vm.$t("confirm"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.isShowDate = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-spacer')],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.customerList,"label":_vm.$t('choose_a_checker'),"placeholder":_vm.$t('input_three_charector'),"item-text":"customer_fullname","outlined":"","rules":[_vm.required],"dense":"","search-input":_vm.searchTextCustomer,"no-data-text":_vm.statusText,"loading":_vm.searchCustomerLoading,"auto-select-first":"","item-value":"customer_id_pri"},on:{"update:searchInput":function($event){_vm.searchTextCustomer=$event},"update:search-input":function($event){_vm.searchTextCustomer=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer_fullname)+" ("+_vm._s(item.customer_id)+") ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer_fullname)+" ("+_vm._s(item.customer_id)+") ")]}}]),model:{value:(_vm.customer_id_pri),callback:function ($$v) {_vm.customer_id_pri=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"customer_id_pri"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"type":"number","outlined":"","rules":[_vm.required],"dense":"","label":_vm.$t('drug_in_sachets')},model:{value:(_vm.drugsticker_amount),callback:function ($$v) {_vm.drugsticker_amount=$$v},expression:"drugsticker_amount"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('unit'),"disabled":""},model:{value:(_vm.drugsticker_unit),callback:function ($$v) {_vm.drugsticker_unit=$$v},expression:"drugsticker_unit"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","rules":[_vm.required],"label":_vm.$t('price'),"dense":"","type":"number"},model:{value:(_vm.drugsticker_price),callback:function ($$v) {_vm.drugsticker_price=$$v},expression:"drugsticker_price"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","label":_vm.$t('how_to_use'),"rows":"2"},model:{value:(_vm.drugsticker_direction),callback:function ($$v) {_vm.drugsticker_direction=$$v},expression:"drugsticker_direction"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.loading,"disabled":_vm.loading|| !_vm.drug.drug_id_pri}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")]),_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){return _vm.$emit('update:is-add-new-drug-label', false)}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }