<template>
  <div>
    <v-dialog
      v-model="isEditDrugLabel"
      persistent
      max-width="900"
    >
      <v-card>
        <v-card-title>
          {{ $t('edit_drug_label') }}
        </v-card-title>
        <v-form
          ref="formEditDrugLabel"
          @submit.prevent="updateDrugLabel"
        >
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="drug"
                  outlined
                  :label="$t('list_drug_equipment')"
                  dense
                  disabled
                  return-object
                  :search-input.sync="search"
                  :filter="customFilter"
                  :loading="drugLoading"
                  :no-data-text="drugLoading?$t('data_loading'):$t('no_information')"
                  :items="dataDrugList"
                  item-value="drug_id_pri"
                >
                  <template v-slot:item="{ item }">
                    ({{ item.drug_id }})  {{ item.drug_name }} <v-spacer></v-spacer><DrugStatus
                      class="me-2"
                      :type="item.drug_category_id"
                    />
                  </template>
                  <template v-slot:selection="{ item }">
                    ({{ item.drug_id }})  {{ item.drug_name }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-dialog
                  ref="date"
                  v-model.trim="isShowDate"
                  :return-value.sync="drugsticker_expdate"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dataEditLocal.drugsticker_expdate"
                      outlined
                      hide-details
                      readonly
                      disabled
                      :rules="[required]"
                      dense
                      :label="$t('date')"
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model.trim="drugsticker_expdate"
                    :locale="$i18n.locale"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.date.save(drugsticker_expdate)"
                    >
                      {{ $t("confirm") }}
                    </v-btn>
                    <v-btn
                      text
                      color="secondary"
                      @click="isShowDate = false"
                    >
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="dataEditLocal.customer_id_pri"
                  :items="customerList"
                  :label="$t('choose_a_checker')"
                  :placeholder="$t('input_three_charector')"
                  item-text="customer_fullname"
                  outlined
                  :rules="[required]"
                  dense
                  disabled
                  :search-input.sync="searchTextCustomer"
                  :no-data-text="statusText"
                  :loading="searchCustomerLoading"
                  auto-select-first
                  item-value="customer_id_pri"
                >
                  <template v-slot:item="{ item }">
                    {{ item.customer_fullname }}
                    ({{ item.customer_id }})
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ item.customer_fullname }}
                    ({{ item.customer_id }})
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="dataEditLocal.drugsticker_amount"
                  type="number"
                  outlined
                  disabled
                  :rules="[required]"
                  dense
                  :label="$t('drug_in_sachets')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="dataEditLocal.drugsticker_unit"
                  outlined
                  dense
                  :label="$t('unit')"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="dataEditLocal.drugsticker_price"
                  outlined
                  :rules="[required]"
                  disabled
                  :label="$t('price')"
                  dense
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="dataEditLocal.drugsticker_direction"
                  outlined
                  :label="$t('how_to_use')"
                  rows="2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading|| !drug.drug_id_pri"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-edit-drug-label', false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import drugStore from '@/api/DrugManagement/drugStore'
import DrugStatus from '@/components/basicComponents/DrugStatus.vue'
import customerSelection from '@/api/customerSelection'
import shop from '@/api/shop'
import { i18n } from '@/plugins/i18n'
import { required } from '@/@core/utils/validation'
import drugLabel from '@/api/drugAndCert/drugLabel'
import store from '@/store'

export default {
  components: {
    DrugStatus,
  },
  model: {
    prop: 'isEditDrugLabel',
    event: 'update:is-edit-drug-label',
  },
  props: {
    isEditDrugLabel: { type: Boolean, default: false },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const moment = require('moment')

    const dataEditLocal = ref({})
    const isShowDate = ref(false)
    const drugsticker_expdate = ref(moment(new Date()).format('YYYY-MM-DD'))
    const dataDrugList = ref([])
    const drug_id_pri = ref('')
    const drug = ref({})
    const drugLoading = ref(false)
    const search = ref()
    const drugsticker_amount = ref('0')
    const drugsticker_unit = ref('0')
    const drugsticker_price = ref('0')
    const drugsticker_direction = ref('')

    const customerList = ref([])
    const searchTextCustomer = ref('')
    const shop_id_pri = ref('')
    const searchCustomerLoading = ref(false)
    const statusText = ref(i18n.t('input_three_charector'))
    const customer_id_pri = ref('')

    const { drugList } = drugStore
    const { shop: shopGet } = shop
    const { customerSelectionList } = customerSelection

    const formEditDrugLabel = ref(null)
    const loading = ref(false)

    const { drugLabelUpdate } = drugLabel
    const updateDrugLabel = () => {
      const isFormValid = formEditDrugLabel.value.validate()
      if (!isFormValid) return
      loading.value = true
      drugLabelUpdate(dataEditLocal.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-edit-drug-label', false)
        loading.value = false
        emit('onUpdate')
      })
    }
    const getDrugList = () => {
      drugLoading.value = true
      drugList({
        searchtext: '',
        drug_status_id: 1,
        drug_category_id: '',
        druggroup_id: '',
        drugtype_id: '',
        active_page: 1,
        per_page: '0',
      }).then(res => {
        dataDrugList.value = res.data
        drug.value = res.data.find(item => item.drug_id_pri === props.dataEdit.drug_id_pri)
        drugLoading.value = false
      })
    }
    const getShop = () => {
      shopGet().then(res => {
        shop_id_pri.value = res.shop_id_pri
      })
    }
    const getCustomerList = () => {
      customerSelectionList({
        searchtext: searchTextCustomer.value,
        shop_id_pri: shop_id_pri.value,
      }).then(res => {
        customerList.value = res
        searchCustomerLoading.value = false
        statusText.value = !res.length ? i18n.t('no_data') : i18n.t('input_three_charector')
      })
    }
    const customFilter = (item, queryText) => {
      if (item.drug_id || item.drug_name) {
        const textOne = item.drug_id.toLowerCase()
        const textTwo = item.drug_name.toLowerCase()
        const searchText = queryText.toLowerCase()

        return (
          textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
        )
      }

      watch(() => searchTextCustomer, val => {
        if (val.length >= 3) {
          statusText.value = i18n.t('data_loading')
          searchCustomerLoading.value = true
          getCustomerList()
        } else {
          customerList.value = []
          statusText.value = i18n.t('input_three_charector')
        }
      })

      return null
    }

    watch(() => props.isEditDrugLabel, val => {
      if (val) {
        getShop()
        getDrugList()
      }
    })

    watch(searchTextCustomer, value => {
      if (value && value.length >= 3) {
        statusText.value = i18n.t('data_loading')
        searchCustomerLoading.value = true
        getCustomerList()
      } else {
        customerList.value = []
        statusText.value = i18n.t('input_three_charector')
      }
    })

    watch((drug), val => {
      if (val) {
        drugsticker_unit.value = val.drug_unit
        drugsticker_direction.value = val.drug_direction
      }
    })

    watch(() => props.dataEdit, val => {
      getCustomerList()
      if (val) {
        dataEditLocal.value = JSON.parse(JSON.stringify(val))
      }
    })

    return {
      dataEditLocal,
      required,
      loading,
      updateDrugLabel,
      formEditDrugLabel,
      drug,
      drugsticker_amount,
      drugsticker_price,
      isShowDate,
      drugsticker_expdate,
      drugLoading,
      dataDrugList,
      drug_id_pri,
      customer_id_pri,
      customerList,
      searchTextCustomer,
      drugsticker_unit,
      searchCustomerLoading,
      drugsticker_direction,
      statusText,
      search,
      customFilter,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
