import { required } from '@/@core/utils/validation'
import drugLabel from '@/api/drugAndCert/drugLabel'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import router from '@/router'
import { ref, watch } from '@vue/composition-api'

export default function useDrugLabel() {
  const loading = ref(false)
  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const columns = ref([
    {
      text: '#',
      align: 'start',
      sortable: false,
      value: 'drugsticker_id',
      width: 30,
    },
    {
      text: i18n.t('receipt_number'),
      value: 'order_id',
      width: 150,
      align: 'center',
    },
    {
      text: i18n.t('drug_name'),
      value: 'drug_name',
      width: 150,
    },
    {
      text: i18n.t('customer_patient'),
      value: 'customer_fullname',
      width: 200,
    },
    {
      text: i18n.t('how_to_use'),
      value: 'drugsticker_direction',
      width: 300,
    },
    {
      text: i18n.t('drug_in_sachets'),
      width: 165,
      align: 'center',
      value: 'drugsticker_amount',
    },
    {
      text: i18n.t('unit'),
      value: 'drugsticker_unit',
      width: 90,
    },
    {
      text: i18n.t('price'),
      align: 'right',
      value: 'drugsticker_price',
      width: 100,
    },
    {
      text: i18n.t('expire_date'),
      value: 'drugsticker_expdate',
      width: 150,
    },

    {
      text: i18n.t('option'),
      value: 'actions',
      width: 150,
      sortable: false,
      align: 'center',
    },
  ])
  const segmentId = ref(0)
  const totalPage = ref(0)
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const options = ref({})
  const isPrintDrugLabel = ref(false)
  const aloneIdPrint = ref(null)

  const language = ref('1')

  const moment = require('moment')

  const searchtext = ref('')
  const drugsticker_modify = ref(moment(new Date()).format('YYYY-MM-DD'))
  const isShowDate = ref(false)

  const isEditDrugLabel = ref(false)
  const dataEdit = ref({})

  const isDelete = ref(false)
  const drugsticker_id = ref('')

  const selected = ref([])

  const { drugLabelList } = drugLabel

  const fetchDataTable = async event => {
    loading.value = true
    await drugLabelList({
      searchtext: searchtext.value,
      drugsticker_modify: drugsticker_modify.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }).then(res => {
      const { data, count, segment, count_of_page } = res
      dataTableList.value = data
      totalDataTableList.value = count
      segmentId.value = segment
      totalPage.value = count_of_page
      if (event == 'add') {
        options.value.page = count_of_page
      } else if (event == 'delete') {
        if (+options.value.page > +count_of_page) {
          options.value.page = count_of_page
          fetchDataTable()
        }
      }
      loading.value = false
    })
  }

  const isAddNewDrugLabel = ref(false)

  const sendPrint = () => {
    const ids = []
    if (aloneIdPrint.value) {
      ids.push(aloneIdPrint.value)
    } else {
      selected.value.forEach(element => {
        ids.push(element.drugsticker_id)
      })
    }
    const routeData = router.resolve({
      name: 'printDrugLabel',
      query: {
        id: String(ids),
        language: language.value == 1 ? 'th' : 'en',
      },
    })
    window.open(routeData.href, '_blank')
  }

  watch([searchtext, drugsticker_modify, options], (newvalue, oldvalue) => {
    if (newvalue[0] !== oldvalue[0] || newvalue[1] !== oldvalue[1]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    isPrintDrugLabel,
    language,
    isDelete,
    drugsticker_id,
    totalPage,
    dataEdit,
    isEditDrugLabel,
    isAddNewDrugLabel,
    selected,
    isShowDate,
    loading,
    aloneIdPrint,
    dataTableList,
    totalDataTableList,
    columns,
    segmentId,
    footer,
    options,
    searchtext,
    drugsticker_modify,
    fetchDataTable,
    required,
    sendPrint,
  }
}
